import React, { useState, useCallback } from 'react';
import { Flex, Block, ThemePicker } from 'vcc-ui';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import SharedVideo from '@vcc-www/video';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import { Icon } from '@vcc-www/video/Video.icons';
import { Button, Click } from '@vcc-www/buttons';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import {
  DEFAULT_DESKTOP_RATIO,
  DEFAULT_MOBILE_RATIO,
} from '@vcc-www/content-types/video.constants';
import VideoProps from './VideoProps';
import {
  buttonCSS,
  videoFrameCSS,
  videoContainerCSS,
  youtubeVideoContainerCSS,
} from './sharedCss';

/**
 * @deprecated Deprecated version of Video component that uses deprecated fields
 */
const VideoDeprecated: React.FC<React.PropsWithChildren<VideoProps>> = ({
  content,
  ...params
}) => {
  const {
    youtubeURL,
    videoDesktopMp4,
    videoMobileMp4,
    videoDesktopWebM,
    videoMobileWebM,
    posterImageLandscape,
    posterImagePortrait,
    shouldAutoplay,
    loop,
    controls,
    largePlayIcon,
    centeredPlayIcon,
    useLoopVideoAsPosterForYouTube,
    subtitleTrack,
    ...props
  } = content;

  return (
    <SectionContained {...params} data-testid="video:deprecated">
      {youtubeURL ? (
        <YouTubeVideo content={content} {...params} />
      ) : (
        <SharedVideo
          poster={{
            default:
              posterImagePortrait?.src || posterImageLandscape?.src || '',
            fromM: posterImageLandscape?.src,
          }}
          srcMp4={{
            default: videoMobileMp4?.src || videoDesktopMp4?.src || '',
            fromM: videoDesktopMp4?.src,
          }}
          srcWebM={{
            default: videoMobileWebM?.src || videoDesktopWebM?.src || '',
            fromM: videoDesktopWebM?.src,
          }}
          shouldAutoplay={shouldAutoplay}
          loop={loop}
          controls={controls}
          largePlayIcon={largePlayIcon}
          centeredPlayIcon={centeredPlayIcon}
          subtitleTrack={subtitleTrack?.src}
          data-autoid="video:video"
          {...props}
        />
      )}
    </SectionContained>
  );
};

const isDarkTheme = (key: VideoProps['background'] = 'dark') =>
  ['black', 'dark'].includes(key);
const getAspectTuple = (ratio: string = '') => ratio?.split(':').map(Number);

const YouTubeVideo: React.FC<React.PropsWithChildren<VideoProps>> = ({
  content,
  background = 'dark',
}) => {
  const { languageCode } = useCurrentMarketSite();

  const {
    youtubeURL,
    videoDesktopMp4,
    videoMobileMp4,
    videoDesktopWebM,
    videoMobileWebM,
    posterImageLandscape,
    posterImagePortrait,
    largePlayIcon,
    centeredPlayIcon,
    useLoopVideoAsPosterForYouTube,
    subtitleTrack,
    ctaLabel,
    mobileAspectRatio = DEFAULT_MOBILE_RATIO,
    desktopAspectRatio = DEFAULT_DESKTOP_RATIO,
    youtubeIframeTitle,
  } = content;

  const aspectRatio: AspectRatio = {
    untilM: getAspectTuple(mobileAspectRatio),
    default: getAspectTuple(desktopAspectRatio),
  };
  const hasLoopVideo = !!(
    videoDesktopMp4 ||
    videoDesktopWebM ||
    videoMobileMp4 ||
    videoMobileWebM
  );

  const [videoIsStarted, setVideoIsStarted] = useState(
    (!posterImageLandscape && !hasLoopVideo) ||
      (!useLoopVideoAsPosterForYouTube && hasLoopVideo),
  );

  const startVideo = useCallback(() => {
    setVideoIsStarted(true);
  }, []);

  const hasCta = !!ctaLabel;
  const iconId = videoDesktopMp4?.src || 'video' + videoDesktopWebM?.src;

  return (
    <ThemePicker variant={isDarkTheme(background) ? 'dark' : 'light'}>
      {videoIsStarted ? (
        <Flex
          extend={videoContainerCSS(aspectRatio, !!posterImagePortrait?.src)}
        >
          <iframe
            style={videoFrameCSS}
            title={youtubeIframeTitle}
            frameBorder={0}
            allowFullScreen
            src={`${youtubeURL}?color=white&cc_load_policy=1&rel=0&autoplay=${
              posterImageLandscape || hasLoopVideo ? 1 : 0
            }&cc_lang_pref=${languageCode}&hl=${languageCode}`}
            allow="autoplay"
          />
        </Flex>
      ) : (
        <Flex extend={youtubeVideoContainerCSS}>
          <Click
            onClick={startVideo}
            // @ts-ignore Check if this is correct value to track for ga4
            trackEventAction="video-toggle"
            trackEventLabel="play"
          >
            {useLoopVideoAsPosterForYouTube && hasLoopVideo ? (
              <SharedVideo
                aspectRatio={aspectRatio}
                poster={{
                  default:
                    posterImagePortrait?.src || posterImageLandscape?.src || '',
                  fromM: posterImageLandscape?.src,
                }}
                srcMp4={{
                  default: videoMobileMp4?.src || videoDesktopMp4?.src || '',
                  fromM: videoDesktopMp4?.src,
                }}
                srcWebM={{
                  default: videoMobileWebM?.src || videoDesktopWebM?.src || '',
                  fromM: videoDesktopWebM?.src,
                }}
                subtitleTrack={subtitleTrack?.src}
                loop
                controls="disabled"
              />
            ) : (
              <Image_DEPRECATED
                sources={{
                  default:
                    posterImagePortrait?.src || posterImageLandscape?.src || '',
                  fromM: posterImageLandscape?.src,
                }}
                alt={posterImageLandscape?.alt || ''}
                {...(posterImagePortrait?.src && {
                  aspectRatio,
                  objectFit: 'cover',
                })}
              />
            )}
            {!hasCta && (
              <Block
                extend={buttonCSS(largePlayIcon, centeredPlayIcon, hasCta)}
              >
                <Icon type="play" id={iconId} />
              </Block>
            )}
          </Click>
          {hasCta && (
            <Block extend={buttonCSS(largePlayIcon, centeredPlayIcon, hasCta)}>
              <Button
                onClick={startVideo}
                intent="secondary"
                // @ts-ignore Check if this is correct value to track for ga4
                trackEventAction="play"
                trackEventLabel="video-toggle"
              >
                {ctaLabel}
              </Button>
            </Block>
          )}
        </Flex>
      )}
    </ThemePicker>
  );
};

export default VideoDeprecated;
