import { useEffect, useRef } from 'react';

import {
  AspectRatio,
  Sources as PosterSources,
  ResponsiveAspectRatio,
} from '@collab/types/types';
import getAspectRatio from '@collab/utils/getAspectRatio';
import { SharedComponentsDictionariesProvider } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import { Sources } from '@vcc-www/utils/getMediaSources';
import VCCVideo from '@vcc-www/video';
import { LoadingType } from '@vcc-www/video/VideoCore';

import styles from './Video.module.css';

export type VideoProps = {
  ariaLabel?: string;
  aspectRatio?: AspectRatio | ResponsiveAspectRatio;
  autoPlay?: boolean;
  controls?: 'disabled' | 'enabled' | 'extended' | 'none';
  disableRemotePlayback?: boolean;
  loading?: LoadingType;
  loop?: boolean;
  poster?: string | PosterSources;
  srcMp4?: string | Sources;
  srcWebM?: string | Sources;
};

const Video: React.FC<VideoProps> = ({
  ariaLabel,
  aspectRatio = [21, 9],
  autoPlay = true,
  controls = 'disabled',
  disableRemotePlayback = false,
  loading,
  loop = true,
  poster,
  srcMp4,
  srcWebM,
}) => (
  <VideoWrapper disableRemotePlayback={disableRemotePlayback}>
    <SharedComponentsDictionariesProvider
      locale="en"
      dictionaries={{
        'VideoPlayer.controls.pause': 'pause',
        'VideoPlayer.controls.play': 'play',
      }}
    >
      <VCCVideo
        aria-label={ariaLabel}
        aspectRatio={getAspectRatio(aspectRatio)}
        controls={controls}
        loading={loading}
        loop={loop}
        muted
        poster={poster ? getPosterSources(poster) : undefined}
        shouldAutoplay={autoPlay}
        srcMp4={srcMp4}
        srcWebM={srcWebM}
      />
    </SharedComponentsDictionariesProvider>
  </VideoWrapper>
);

const VideoWrapper: React.FC<{
  disableRemotePlayback: boolean;
  children: React.ReactNode;
}> = ({ children, disableRemotePlayback }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (disableRemotePlayback && containerRef.current) {
      const videoEl = containerRef?.current.querySelector('video');
      if (videoEl) {
        videoEl.setAttribute('disableRemotePlayback', 'true');
      }
    }
  }, [containerRef, disableRemotePlayback]);

  return (
    <div ref={containerRef} className={styles.videoWrapper}>
      {children}
    </div>
  );
};

const getPosterSources = (src: string | PosterSources) => {
  if (typeof src === 'string') {
    return src;
  }

  return {
    default: src.default,
    untilM: src.mobile,
  };
};

export default Video;
