import { AspectRatio, ResponsiveAspectRatio } from '@collab/types/types';

const getAspectRatio = (aspectRatio: AspectRatio | ResponsiveAspectRatio) => {
  if (Array.isArray(aspectRatio)) return aspectRatio;

  return {
    default: aspectRatio.default,
    ...(aspectRatio.mobile && {
      untilM: aspectRatio.mobile,
    }),
  };
};

export default getAspectRatio;
